
import { Options, Vue, prop } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ProductionPhaseConfigurationClient } from '@/services/Services';
import editSingleGLayerModal from '../modals/editSingleGLayerModal.vue';
import editWattMinLayerModal from '../modals/editWattMinLayerModal.vue';
import editGWattMinLayerModal from '../modals/editGWattMinLayerModal.vue';
import editGTripleWattMinLayerModal from '../modals/editGTripleWattMinLayerModal.vue';
import editGWattMinM3LayerModal from '../modals/editGWattMinM3LayerModal.vue';
import store from '@/store';
import utils from '@/utils';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class editLayerProductionProcessOptionalDetailed extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() layer: OM.LayerComposition;
    @Prop() productionPhase: string;
    @Prop() allProductionProcesses: OM.ProductionProcessListVM[];
    @Prop() currentProductionProcesses: OM.FabricProductionProcess[];
    @Prop() countries: OM.TextIdentifier[];

    openedCarousel: string[] = [];
    hideEditAction: boolean = false;
    isLeather: boolean = false;

    created() {
        this.hideEditAction = utils.checkIfProductionPhaseIsNextFabric(this.productionPhase);
        ProductionPhaseConfigurationClient.checkIfPhaseIsLeather(this.productionPhase)
        .then(x => {
            this.isLeather = x;
        })
    }

    get isWeastagePercentageGSMVisible(){
        return this.currentProductionProcesses.filter( x => x.productionPhase.text == this.productionPhase)
            .some( x => x.productionPhase.showWastage);
    }

    get groupedByProductionGroup() {
        return this.currentProductionProcesses.filter( x => x.productionPhase.text == this.productionPhase)
            .reduce((groups, process) => {
            const group = process.productionGroup || 'Unknown'; // Se il gruppo è null o undefined

            if (!groups[group]) {
            groups[group] = {};
            }

            const processName = process.name || 'Unknown'; // Usa il nome del processo o 'Unknown' se non esiste

            // Se il nome del processo non esiste ancora nel gruppo, inizializziamo l'array
            if (!groups[group][processName]) {
            groups[group][processName] = {
                processes: [], // Lista dei processi per nome
                materials: [] // Lista dei materiali per raggrupparli
            };
            }

            // Aggiungi il processo all'elenco dei processi per quel nome
            groups[group][processName].processes.push(process);

            // Aggiungiamo i materiali e le loro proprietà associate, raggruppando
            if (process.rawMaterial && process.rawMaterial.text) {
            groups[group][processName].materials.push({
                name: process.rawMaterial.text,
                cO2e: process.cO2e,
                productionCountry: process.productionCountry ? process.productionCountry.text : 'N/A'
            });
            }

            return groups;
        }, {});
    }

    get allGroupedMaterials() {
        const materialsMap = {}; // Usiamo un oggetto per mappare i materiali per nome

        // Iteriamo su tutti i gruppi e i processi per raccogliere i materiali
        Object.values(this.groupedByProductionGroup).forEach(processesByName => {
            Object.values(processesByName).forEach(processData => {
                processData.materials.forEach(material => {
                    if (!materialsMap[material.name]) {
                    // Se il materiale non esiste ancora, lo aggiungiamo
                    materialsMap[material.name] = {
                        name: material.name,
                        cO2e: material.cO2e, // Iniziamo con il valore di cO2e corrente
                        productionCountry: material.productionCountry // Manteniamo il primo productionCountry trovato
                    };
                    } else {
                    // Se il materiale esiste già, sommiamo il valore di cO2e
                    materialsMap[material.name].cO2e += material.cO2e;
                    }
                });
            });
        });

        // Ritorniamo la lista dei materiali raggruppati
        return Object.values(materialsMap);
    }

    openEditModal(processName: string, emissionCalculation: any) {
        if(this.checkIfIsSingleG(emissionCalculation)) {
            this.$opModal.show(editSingleGLayerModal, {
                productionProcessName: processName,
                emissionCalculation: emissionCalculation as OM.SingleGModel,
                saveCallback: (updatedModel: OM.SingleGModel) => {
                    var vmToUpdate = new OM.EditEstimationLayerSingleGProductionProcessVM();
                    vmToUpdate.gramsValue = updatedModel.grams;
                    vmToUpdate.identifier = this.estimationIdentifier;
                    vmToUpdate.layerName = this.layer.layerName;
                    vmToUpdate.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == processName).identifier;
                    
                    store.state.estimationClient.editSingleGLayerProductionProcess(vmToUpdate)
                    .then( x => {
                        this.$opModal.closeLast();
                        this.$emit("init");
                    })
                }
            })
        }
        else if(this.checkIfIsWattMin(emissionCalculation)) {
            this.$opModal.show(editWattMinLayerModal, {
                productionProcessName: processName,
                emissionCalculation: emissionCalculation as OM.WattMinModel,
                saveCallback: (updatedModel: OM.WattMinModel) => {
                    var vmToUpdate = new OM.EditEstimationLayerWattMinProductionProcessVM();
                    vmToUpdate.watt = updatedModel.watt;
                    vmToUpdate.minutes = updatedModel.minutes;
                    vmToUpdate.identifier = this.estimationIdentifier;
                    vmToUpdate.layerName = this.layer.layerName;
                    vmToUpdate.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == processName).identifier;
                    
                    store.state.estimationClient.editWattMinLayerProductionProcess(vmToUpdate)
                    .then( x => {
                        this.$opModal.closeLast();
                        this.$emit("init");
                    })
                }
            })
        }
        else if(this.checkIfIsGWattMinOrVariant(emissionCalculation)) {
            this.$opModal.show(editGWattMinLayerModal, {
                productionProcessName: processName,
                emissionCalculation: emissionCalculation as OM.GWattMinModel,
                saveCallback: (updatedModel: OM.GWattMinModel) => {
                    var vmToUpdate = new OM.EditEstimationLayerGWattMinProductionProcessVM();
                    vmToUpdate.grams = updatedModel.grams;
                    vmToUpdate.watt = updatedModel.watt;
                    vmToUpdate.minutes = updatedModel.minutes;
                    vmToUpdate.identifier = this.estimationIdentifier;
                    vmToUpdate.layerName = this.layer.layerName;
                    vmToUpdate.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == processName).identifier;
                    
                    if(this.checkIfIsGWattMin(emissionCalculation)) {
                        store.state.estimationClient.editGWattMinLayerProductionProcess(vmToUpdate)
                        .then( x => {
                            this.$opModal.closeLast();
                            this.$emit("init");
                        })
                    }
                    else {
                        store.state.estimationClient.editGWattMinVariantLayerProductionProcess(vmToUpdate)
                        .then( x => {
                            this.$opModal.closeLast();
                            this.$emit("init");
                        })
                    }
                }
            })
        }
        else if(this.checkIfIsGTripleWattMin(emissionCalculation)) {
            this.$opModal.show(editGTripleWattMinLayerModal, {
                productionProcessName: processName,
                emissionCalculation: emissionCalculation as OM.GTripleWattMinModel,
                saveCallback: (updatedModel: OM.GTripleWattMinModel) => {
                    var vmToUpdate = new OM.EditEstimationLayerGTripleWattMinProductionProcessVM();
                    vmToUpdate.grams = updatedModel.grams;
                    vmToUpdate.watt = updatedModel.watt;
                    vmToUpdate.watt2 = updatedModel.watt2;
                    vmToUpdate.watt3 = updatedModel.watt3;
                    vmToUpdate.minutes = updatedModel.minutes;
                    vmToUpdate.identifier = this.estimationIdentifier;
                    vmToUpdate.layerName = this.layer.layerName;
                    vmToUpdate.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == processName).identifier;
                    
                    store.state.estimationClient.editGTripleWattMinLayerProductionProcess(vmToUpdate)
                    .then( x => {
                        this.$opModal.closeLast();
                        this.$emit("init");
                    })
                }
            })
        }
        else if(this.checkIfIsGWattMinM3(emissionCalculation)) {
            this.$opModal.show(editGWattMinM3LayerModal, {
                productionProcessName: processName,
                emissionCalculation: emissionCalculation as OM.GWattMinM3Model,
                saveCallback: (updatedModel: OM.GWattMinM3Model) => {
                    var vmToUpdate = new OM.EditEstimationLayerGWattMinM3ProductionProcessVM();
                    vmToUpdate.grams = updatedModel.grams;
                    vmToUpdate.watt = updatedModel.watt;
                    vmToUpdate.minutes = updatedModel.minutes;
                    vmToUpdate.m3 = updatedModel.m3;
                    vmToUpdate.identifier = this.estimationIdentifier;
                    vmToUpdate.layerName = this.layer.layerName;
                    vmToUpdate.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == processName).identifier;
                    
                    store.state.estimationClient.editGWattMinM3LayerProductionProcess(vmToUpdate)
                    .then( x => {
                        this.$opModal.closeLast();
                        this.$emit("init");
                    })
                }
            })
        }
    }

    removeProcess(processName: string, emissionCalculation: any){
        if(this.checkIfIsSingleG(emissionCalculation)) {
            (emissionCalculation as OM.SingleGModel).grams.value = 0;
            var vmToUpdateSingleG = new OM.EditEstimationLayerSingleGProductionProcessVM();
            vmToUpdateSingleG.gramsValue = (emissionCalculation as OM.SingleGModel).grams;
            vmToUpdateSingleG.identifier = this.estimationIdentifier;
            vmToUpdateSingleG.layerName = this.layer.layerName;
            vmToUpdateSingleG.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == processName).identifier;
            
            store.state.estimationClient.editSingleGLayerProductionProcess(vmToUpdateSingleG)
            .then( x => {
                this.$emit("init");
            })
        }
        else if(this.checkIfIsWattMin(emissionCalculation)) {
            (emissionCalculation as OM.WattMinModel).watt.value = 0;
            (emissionCalculation as OM.WattMinModel).minutes.value = 0;
            var vmToUpdateWattMin = new OM.EditEstimationLayerWattMinProductionProcessVM();
            vmToUpdateWattMin.watt = (emissionCalculation as OM.WattMinModel).watt;
            vmToUpdateWattMin.minutes = (emissionCalculation as OM.WattMinModel).minutes;
            vmToUpdateWattMin.identifier = this.estimationIdentifier;
            vmToUpdateWattMin.layerName = this.layer.layerName;
            vmToUpdateWattMin.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == processName).identifier;
            
            store.state.estimationClient.editWattMinLayerProductionProcess(vmToUpdateWattMin)
            .then( x => {
                this.$emit("init");
            })
        }
        else if(this.checkIfIsGWattMinOrVariant(emissionCalculation)) {
            (emissionCalculation as OM.GWattMinModel).grams.value = 0;
            (emissionCalculation as OM.GWattMinModel).watt.value = 0;
            (emissionCalculation as OM.GWattMinModel).minutes.value = 0;
            
            var vmToUpdateGWattMin = new OM.EditEstimationLayerGWattMinProductionProcessVM();
            vmToUpdateGWattMin.grams = (emissionCalculation as OM.GWattMinModel).grams;
            vmToUpdateGWattMin.watt = (emissionCalculation as OM.GWattMinModel).watt;
            vmToUpdateGWattMin.minutes = (emissionCalculation as OM.GWattMinModel).minutes;
            vmToUpdateGWattMin.identifier = this.estimationIdentifier;
            vmToUpdateGWattMin.layerName = this.layer.layerName;
            vmToUpdateGWattMin.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == processName).identifier;
            
            if(this.checkIfIsGWattMin(emissionCalculation)) {
                store.state.estimationClient.editGWattMinLayerProductionProcess(vmToUpdateGWattMin)
                .then( x => {
                    this.$emit("init");
                })
            }
            else {
                store.state.estimationClient.editGWattMinVariantLayerProductionProcess(vmToUpdateGWattMin)
                .then( x => {
                    this.$emit("init");
                })
            }
        }
        else if(this.checkIfIsGTripleWattMin(emissionCalculation)) {
            (emissionCalculation as OM.GTripleWattMinModel).grams.value = 0;
            (emissionCalculation as OM.GTripleWattMinModel).watt.value = 0;
            (emissionCalculation as OM.GTripleWattMinModel).watt2.value = 0;
            (emissionCalculation as OM.GTripleWattMinModel).watt3.value = 0;
            (emissionCalculation as OM.GTripleWattMinModel).minutes.value = 0;
               
            var vmToUpdateGTripleWattMin = new OM.EditEstimationLayerGTripleWattMinProductionProcessVM();
            vmToUpdateGTripleWattMin.grams = (emissionCalculation as OM.GTripleWattMinModel).grams;
            vmToUpdateGTripleWattMin.watt = (emissionCalculation as OM.GTripleWattMinModel).watt;
            vmToUpdateGTripleWattMin.watt2 = (emissionCalculation as OM.GTripleWattMinModel).watt2;
            vmToUpdateGTripleWattMin.watt3 = (emissionCalculation as OM.GTripleWattMinModel).watt3;
            vmToUpdateGTripleWattMin.minutes = (emissionCalculation as OM.GTripleWattMinModel).minutes;
            vmToUpdateGTripleWattMin.identifier = this.estimationIdentifier;
            vmToUpdateGTripleWattMin.layerName = this.layer.layerName;
            vmToUpdateGTripleWattMin.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == processName).identifier;
            
            store.state.estimationClient.editGTripleWattMinLayerProductionProcess(vmToUpdateGTripleWattMin)
            .then( x => {
                this.$emit("init");
            })
        }
        else if(this.checkIfIsGWattMinM3(emissionCalculation)) {
            (emissionCalculation as OM.GWattMinM3Model).grams.value = 0;
            (emissionCalculation as OM.GWattMinM3Model).watt.value = 0;
            (emissionCalculation as OM.GWattMinM3Model).minutes.value = 0;
            (emissionCalculation as OM.GWattMinM3Model).m3.value = 0;
            
            var vmToUpdateGWattMinM3 = new OM.EditEstimationLayerGWattMinM3ProductionProcessVM();
            vmToUpdateGWattMinM3.grams = (emissionCalculation as OM.GWattMinM3Model).grams;
            vmToUpdateGWattMinM3.watt = (emissionCalculation as OM.GWattMinM3Model).watt;
            vmToUpdateGWattMinM3.minutes = (emissionCalculation as OM.GWattMinM3Model).minutes;
            vmToUpdateGWattMinM3.m3 = (emissionCalculation as OM.GWattMinM3Model).m3;
            vmToUpdateGWattMinM3.identifier = this.estimationIdentifier;
            vmToUpdateGWattMinM3.layerName = this.layer.layerName;
            vmToUpdateGWattMinM3.productionProcessIdentifier = this.allProductionProcesses.find(x => x.name == processName).identifier;
            
            store.state.estimationClient.editGWattMinM3LayerProductionProcess(vmToUpdateGWattMinM3)
            .then( x => {
                this.$emit("init");
            })
        }
    }

    backToDefaultValues(processName: string) {
        var processIdentifier = this.allProductionProcesses.find(x => x.name == processName).identifier;
        store.state.estimationClient.backToDefaultValuesLayerProductionProcessForEachMaterial(this.estimationIdentifier, processIdentifier, this.layer.layerName)
        .then( x => {
            this.$emit("init");
        })
    }

    checkIfIsSingleG(emissionCalculation: any) {
        return emissionCalculation.type == OM.CalculationType.SingleG;
    }
    checkIfIsWattMin(emissionCalculation: any) {
        return emissionCalculation.type == OM.CalculationType.WattMin;
    }
    checkIfIsGWattMinOrVariant(emissionCalculation: any) {
        return emissionCalculation.type == OM.CalculationType.GWattMin || emissionCalculation.type == OM.CalculationType.GWattMinVariant;
    }
    checkIfIsGWattMin(emissionCalculation: any) {
        return emissionCalculation.type == OM.CalculationType.GWattMin;
    }
    checkIfIsGWattMinVariant(emissionCalculation: any) {
        return emissionCalculation.type == OM.CalculationType.GWattMinVariant;
    }
    checkIfIsGTripleWattMin(emissionCalculation: any) {
        return emissionCalculation.type == OM.CalculationType.GTripleWattMin;
    }
    checkIfIsGWattMinM3(emissionCalculation: any) {
        return emissionCalculation.type == OM.CalculationType.GWattMinM3;
    }
}
