
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({})
export default class editGTripleWattMinLayerModal extends Vue {

    @Prop() productionProcessName: string;
    @Prop() emissionCalculation: OM.GTripleWattMinModel;
    @Prop() saveCallback: (updatedModel: OM.GTripleWattMinModel) => void;

    localModel = new OM.GTripleWattMinModel();

    created() {
        this.localModel = this.emissionCalculation;
    }

    get disabled(){
        return !this.localModel.grams.value || !this.localModel.watt.value || !this.localModel.watt2.value || 
            !this.localModel.watt3.value || !this.localModel.minutes.value;
    }

    save() {
        this.saveCallback(this.localModel);
    }
}
