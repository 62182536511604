
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({})
export default class editWattMinLayerModal extends Vue {

    @Prop() productionProcessName: string;
    @Prop() emissionCalculation: OM.WattMinModel;
    @Prop() saveCallback: (updatedModel: OM.WattMinModel) => void;

    localModel = new OM.WattMinModel();

    created() {
        this.localModel = this.emissionCalculation;
    }

    get disabled(){
        return !this.localModel.watt.value || !this.localModel.minutes.value;
    }

    save() {
        this.saveCallback(this.localModel);
    }
}
